<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.edit_shipment") }}
    </template>
    <template #contentBody>
      <a-spin size="large" :spinning="state.loading">
        <a-row>
          <a-col :span="24">
            <a-steps :current="2" style="max-width: 1800px" size="small">
              <a-step :title="$t('warehouse.create_appointment')" />
              <a-step :title="$t('warehouse.add_shipment')" />
              <a-step class="mr-3" :title="$t('warehouse.edit_shipment')" />
            </a-steps>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="20">
            <a-row class="mt-3" :gutter="[0, 12]">
              <a-col :span="24"
                >{{ $t("warehouse.warehousing_reservation_number") }}:
                {{ state.planNo }}</a-col
              >
              <a-col :span="8"
                >{{ $t("warehouse.transfer_warehouse") }}:
                 {{ state.warehouseAddress?.warehouseNo }}</a-col
              >
              <a-col :span="16">
                {{ $t("warehouse.warehouse_address") }}:
                {{ getAddress() }}
                <span class="ml-2" v-if="state.warehouseAddress">
                  {{ $t("logistics.contacts") }}:
                  {{ getLinkman(state.warehouseAddress) }}
                </span>
              </a-col>
              <a-col :span="8">
                {{ $t("warehouse.type_of_shipping") }}:
                {{
                  $t(
                    $enumLangkey(
                      "transportationMethod",
                      state.transportationMethod
                    )
                  )
                }}
              </a-col>
              <a-col
                :span="8"
                v-if="
                  state.transportationMethod ==
                  transportationMethodEnum.commercialExpress
                "
              >
                {{ $t("warehouse.delivery_way") }}:
                {{ $t($enumLangkey("deliveryWay", state.deliveryType)) }}
              </a-col>
              <a-col
                :span="8"
                v-if="
                  state.transportationMethod ==
                  transportationMethodEnum.commercialExpress
                "
              >
                {{ $t("warehouse.tracking_number") }}: {{ state.trackingNo }}
              </a-col>
              <a-col
                :span="8"
                v-if="
                  state.transportationMethod ==
                  transportationMethodEnum.container
                "
              >
                {{ $t("warehouse.container_type") }}: 
                <span v-if="state.containerType">{{ $t($enumLangkey('containerType', state.containerType)) }}</span>
                <span v-else>-</span>
              </a-col>
              <a-col
                :span="8"
                v-if="
                  state.transportationMethod ==
                  transportationMethodEnum.container
                "
              >
                {{ $t("warehouse.container_no") }}: {{ state.containerNo }}
              </a-col>
              <a-col :span="8">
                {{ $t("warehouse.estimated_arrival_date") }}:
                {{
                  $filters.utcToSpecificTime(
                    state.wareZoneAppointmentDate,
                    state?.wareTimeZone
                  )
                }}
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="4">
            <div class="text-center mt-4">
              <strong>
                {{ $t("warehouse.status") }}
              </strong>
              <div class="mt-3">
                <strong class="text-success">
                  {{ $t($enumLangkey("transitStatus", state.transitStatus)) }}
                </strong>
              </div>
            </div>
          </a-col>
        </a-row>

        <a-row type="flex" justify="space-between" align="middle" class="mt-3">
          <a-col>
            <a-tabs v-model:activeKey="state.tab" @change="handleChangeTab">
              <a-tab-pane
                key="packingList"
                :tab="$t('warehouse.container_loading_list')"
              >
              </a-tab-pane>
              <a-tab-pane
                key="shipmentList"
                :tab="$t('warehouse.shipment_list')"
              >
              </a-tab-pane>
            </a-tabs>
          </a-col>
          <a-col>
            <a-button @click="handleShowShipmentAddModal">
              {{ $t("warehouse.add_shipment") }}
            </a-button>
          </a-col>
        </a-row>

        <!-- 装箱清单 -->
        <div>
          <a-table
            v-show="state.tab == 'packingList'"
            :columns="columnsPackingList"
            :data-source="state.packingList"
            :scroll="{ x: true, y: 600 }"
            :pagination="false"
            size="small"
            :loading="state.tableLoading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template #canScanedBoxNoTitle>
              {{ $t("warehouse.scannable_box_no") }}
              <a-tooltip>
                <template #title>
                  {{ $t("warehouse.scannable_box_no_hint") }}
                </template>
                <QuestionCircleOutlined />
              </a-tooltip>
            </template>

            <template #prediction_size="{ record }">
              {{ record.containerBoxSize?.length }}x{{
                record.containerBoxSize.width
              }}x{{ record.containerBoxSize.height }}cm |
              {{ cmCubicToM(record.containerBoxSize.volume) }}m<sup>3</sup>
            </template>

            <template #prediction_weight="{ record }">
              {{ gToKg(record.containerBoxSize.weight) }}kg
            </template>
            <template #box_type="{ record }">
              <!-- 常規 -->
              <a-tag
                color="green"
                v-if="record.boxType == boxTypeEnum.conventional"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
              <!-- 超规 -->
              <a-tag
                color="warning"
                v-if="record.boxType == boxTypeEnum.overgauge"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
              <!-- 超大件 -->
              <a-tag
                color="error"
                v-if="record.boxType == boxTypeEnum.oversizeUnit"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
            </template>

            <template #operateCustom="{ record }">
              <a-spin
                :spinning="!(record.loading == undefined) && record.loading"
              >
                <a-dropdown-button @click="handleShowEditBoxInfo(record)">
                  {{ $t("common.edit") }}
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                        key="1"
                        @click="handleShowBoxDeleteModal(record)"
                      >
                        {{ $t("common.delete") }}
                      </a-menu-item>
                      <a-menu-item key="2" @click="handlePrintBoxSETB(record)">
                        {{
                          $t("warehouse.print_SETB_labels_inside_the_shipment")
                        }}
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown-button>
              </a-spin>
            </template>
          </a-table>
          <a-row
            type="flex"
            justify="center"
            class="mt-3"
            v-if="state.tab == 'packingList'"
          >
            <a-col>
              <CPager
                @handlePage="handlePagePackingList"
                :page-data="state.packingListPageData"
              ></CPager>
            </a-col>
          </a-row>
        </div>

        <!-- 货件列表 -->
        <div>
          <a-table
            v-show="state.tab == 'shipmentList'"
            :columns="columnsShipmentList"
            :data-source="state.shipmentList"
            :scroll="{ x: true, y: 600 }"
            :pagination="false"
            size="small"
            :loading="state.tableLoading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template #box_amount="{ record }">
              {{ record?.boxCount?.plan }}
            </template>

            <template #volume="{ record }">
              {{ cmCubicToM(record?.volume?.plan) }}
              <span v-if="record?.volume?.plan">m<sup>3</sup></span>
            </template>
            <template #weight="{ record }">
              {{ gToKg(record.weight.plan) }}
              <span v-if="record.weight.plan">kg </span>
            </template>

            <template #operate>
              <!-- <a-button
                @click="handlePrintShipmentSETableBatch"
                :disabled="
                  !state.shipmentList || state.shipmentList.length == 0
                "
                >{{ $t("warehouse.print_SE_shipment_label") }}</a-button
              > -->
            </template>
            <template #operateCustom="{ record }">
              <a-spin
                :spinning="!(record.loading == undefined) && record.loading"
              >
                <a-dropdown-button
                  @click="handleShowShipmentDetailModal(record)"
                >
                  {{ $t("common.details") }}
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                        key="1"
                        @click="handleShowShipmentDetailModal(record, true)"
                      >
                        {{ $t("common.delete") }}
                      </a-menu-item>
                      <!-- <a-menu-item
                        key="2"
                        @click="handlePrintShipmentSETable(record)"
                      >
                        {{ $t("warehouse.print_SE_shipment_label") }}
                      </a-menu-item> -->
                      <a-menu-item
                        key="3"
                        @click="handlePrintShipmentInSETBAll(record)"
                      >
                        {{
                          $t("warehouse.print_SETB_labels_inside_the_shipment")
                        }}
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown-button>
              </a-spin>
            </template>
          </a-table>
          <a-row
            type="flex"
            justify="center"
            class="mt-3"
            v-if="state.tab == 'shipmentList'"
          >
            <a-col>
              <CPager
                @handlePage="handlePageShipmentList"
                :page-data="state.shipmentListPageData"
              ></CPager>
            </a-col>
          </a-row>
        </div>

        <!-- 费用明细 -->
        <div class="mt-3">
            <CFee :fees="state.cost?.fees" :totalFee="state.cost?.totalFee"></CFee>
          <a-row type="flex" justify="end" class="mt-2 mb-5">
            <a-col>
              <div style="width: 500px">
                <a-row type="flex" justify="center" :gutter="[24, 12]">
                  <a-col>
                    <a-popconfirm
                      :title="$t('common.are_you_sure')"
                      @confirm="handleCancelPlan"
                    >
                      <a-button>{{ $t("warehouse.cancel_plan") }}</a-button>
                    </a-popconfirm>
                  </a-col>
                  <a-col>
                    <a-button
                      type="primary"
                      @click="handleShowPayModal"
                      :disabled="state.packingList?.length == 0"
                      >{{
                        $t("warehouse.finalize_the_plan_and_pay_for_it")
                      }}</a-button
                    >
                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>
        </div>

        <!-- 编辑箱信息 -->
        <a-modal
          width="600px"
          v-model:visible="boxInfoModal.visible"
          :title="$t('warehouse.edit_box_info')"
          :cancelText="$t('common.close')"
          :okText="$t('common.confirm')"
          :confirm-loading="boxInfoModal.loading"
          :maskClosable="false"
          @ok="handleSaveBoxInfoModal"
          :centered="true"
        >
          <a-form
            ref="refFormBoxInfo"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 12 }"
            :rules="rulesBoxInfo"
            :model="boxInfoModal"
          >
            <a-form-item
              :label="$t('warehouse.shipment_name')"
              name="shipmentId"
            >
              <a-select
                v-model:value="boxInfoModal.shipmentId"
                allow-clear
                :showSearch="true"
                :loading="state.shipmentListDropdownBoxLoading"
                optionFilterProp="search-key"
              >
                <a-select-option
                  v-for="(item, key) in state?.shipmentListDropdownBox"
                  :key="key"
                  :value="item?.shipmentId"
                  :title="item?.name + item.seShipmentNo"
                  :search-key="item.name + item.seShipmentNo"
                >
                  {{ item.name }} ({{ item.seShipmentNo }})
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              :label="$t('warehouse.scannable_box_no')"
              name="canScanedBoxNo"
            >
              <a-input
                v-model:value="boxInfoModal.box.canScanedBoxNo"
              ></a-input>
            </a-form-item>

            <a-form-item :label="$t('warehouse.box_id')">
              {{ boxInfoModal.boxNo }}
            </a-form-item>

            <a-form-item
              :label="$t('warehouse.prediction_size')"
              name="predictionSize"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-row :gutter="[12, 12]">
                <a-col>
                  <a-form-item class="mb-0">
                    <a-input-number
                      v-model:value="boxInfoModal.box.length"
                      :min="0"
                      allow-clear
                      :precision="0"
                    ></a-input-number>
                  </a-form-item>
                </a-col>
                <a-col>
                  <a-form-item class="mb-0">
                    <span>X</span>
                  </a-form-item>
                </a-col>
                <a-col>
                  <a-form-item class="mb-0">
                    <a-input-number
                      v-model:value="boxInfoModal.box.width"
                      :min="0"
                      allow-clear
                      :precision="0"
                    ></a-input-number>
                  </a-form-item>
                </a-col>
                <a-col>
                  <a-form-item class="mb-0">
                    <span>X</span>
                  </a-form-item>
                </a-col>
                <a-col>
                  <a-form-item class="mb-0">
                    <a-input-number
                      v-model:value="boxInfoModal.box.height"
                      :min="0"
                      allow-clear
                      :precision="0"
                    ></a-input-number>
                    cm
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item
              :label="$t('warehouse.prediction_weight')"
              name="weight"
            >
              <a-input-number
                v-model:value="boxInfoModal.box.weight"
                :min="0"
                allow-clear
                :precision="3"
              ></a-input-number>
              kg
            </a-form-item>
          </a-form>
        </a-modal>

        <!-- 确认删除箱信息 -->
        <a-modal
          v-model:visible="deleteBoxModal.visible"
          :cancelText="$t('common.close')"
          :okText="$t('common.confirm')"
          :confirm-loading="deleteBoxModal.loading"
          :maskClosable="false"
          @ok="handleBoxDelete"
          :centered="true"
        >
          <template #title>
            <span class="text-error">
              <ExclamationCircleOutlined />
              {{ $t("common.delete_prompt") }}
            </span>
          </template>
          <a-form
            ref="refFormBoxInfo"
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 12 }"
          >
            <a-form-item :label="$t('warehouse.shipment_name')">
              {{ deleteBoxModal.name }}
            </a-form-item>

            <a-form-item :label="$t('warehouse.scannable_box_no')">
              {{ deleteBoxModal.canScanedBoxNo }}
            </a-form-item>

            <a-form-item :label="$t('warehouse.box_id')">
              {{ deleteBoxModal.boxNo }}
            </a-form-item>
            <div class="text-center text-warning">
              {{
                $t("warehouse.transfer_warehouse_reservation_removal_prompt")
              }}
            </div>
          </a-form>
        </a-modal>

        <!-- 货件详情 -->
        <a-modal
          width="900px"
          v-model:visible="shipmentDetailModal.visible"
          :title="
            shipmentDetailModal.isDelete
              ? $t('warehouse.delete_shipment')
              : $t('warehouse.detail_shipment')
          "
          :cancelText="$t('common.close')"
          :okText="$t('common.confirm')"
          :confirm-loading="shipmentDetailModal.loading"
          :maskClosable="false"
          :footer="null"
          :centered="true"
        >
          <div>
            {{ $t("warehouse.shipment_name") }}:{{ shipmentDetailModal.name }}
            <span class="ml-5"
              >{{ $t("warehouse.se_shipment_number") }}:{{
                shipmentDetailModal.seShipmentNo
              }}</span
            >
          </div>

          <a-table
            class="mt-3"
            :columns="columnsShipmentDetail"
            :data-source="shipmentDetailModal.list"
            :scroll="{ x: true, y: 600 }"
            :pagination="false"
            size="small"
            :loading="shipmentDetailModal.loading"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template #predictionSize="{ record }">
              {{ record.containerBoxSize?.length }} x
              {{ record.containerBoxSize?.width }} x
              {{ record.containerBoxSize?.height }} cm
            </template>
            <template #predictionWeight="{ record }">
              <span v-if="record.containerBoxSize?.weight"
                >{{ gToKg(record.containerBoxSize?.weight) }} kg</span
              >
              <span v-else>-</span>
            </template>
            <template #boxType="{ record }">
              <!-- 常規 -->
              <a-tag
                color="green"
                v-if="record.boxType == boxTypeEnum.conventional"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
              <!-- 超规 -->
              <a-tag
                color="warning"
                v-else-if="record.boxType == boxTypeEnum.overgauge"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
              <!-- 超大件 -->
              <a-tag
                color="error"
                v-else-if="record.boxType == boxTypeEnum.oversizeUnit"
              >
                {{ $t($enumLangkey("boxType", record.boxType)) }}
              </a-tag>
            </template>
          </a-table>

          <a-row type="flex" justify="center" class="mt-3">
            <a-col>
              <CPager
                @handlePage="handlePageShipmentDetailModal"
                :page-data="shipmentDetailModal.pageData"
                :showLessItems="true"
                :showQuickJumper="false"
              ></CPager>
            </a-col>
          </a-row>

          <a-row
            type="flex"
            justify="center"
            v-if="shipmentDetailModal.isDelete"
            class="mt-3"
          >
            <a-col class="text-error">
              {{
                $t(
                  "warehouse.p0_transfer_warehouse_reservation_delete_prompt",
                  [shipmentDetailModal.pageData.totalCount]
                )
              }}
            </a-col>
          </a-row>

          <a-row type="flex" justify="center" :gutter="[64, 12]" class="mt-3">
            <a-col class="text-error" v-if="shipmentDetailModal.isDelete">
              <a-button
                @click="handleShipmentDelete"
                :loading="shipmentDetailModal.deleteLoading"
                >{{ $t("common.delete") }}</a-button
              >
            </a-col>
            <a-col class="text-error">
              <a-button @click="shipmentDetailModal.visible = false">{{
                $t("common.close")
              }}</a-button>
            </a-col>
          </a-row>
        </a-modal>

        <!-- 添加货件 -->
        <a-modal
          width="1000px"
          v-model:visible="shipmentAddModal.visible"
          :title="$t('warehouse.add_shipment')"
          :cancelText="$t('common.close')"
          :okText="$t('common.confirm')"
          :confirm-loading="shipmentAddModal.loading"
          :maskClosable="false"
          @ok="handleSaveShipmentAddModal"
          :centered="true"
        >
          <a-row>
            <a-col :span="12">
              <a-form-item
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 12 }"
                :label="$t('warehouse.add_type')"
              >
                <a-select v-model:value="shipmentAddModal.addType">
                  <a-select-option value="create">
                    {{ $t("warehouse.create_new_shipment") }}
                  </a-select-option>
                  <a-select-option value="append">
                    {{ $t("warehouse.add_to_existing_shipment") }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="shipmentAddModal.addType == 'append'">
              <a-form-item
                :label="$t('warehouse.shipment_name')"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 12 }"
              >
                <a-select
                  v-model:value="shipmentAddModal.shipmentId"
                  allow-clear
                  :showSearch="true"
                  :loading="state.shipmentListDropdownBoxLoading"
                  optionFilterProp="search-key"
                >
                  <a-select-option
                    v-for="(item, key) in state?.shipmentListDropdownBox"
                    :key="key"
                    :value="item?.shipmentId"
                    :title="item?.name + item.seShipmentNo"
                    :search-key="item.name + item.seShipmentNo"
                  >
                    {{ item.name }} ({{ item.seShipmentNo }})
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12" v-else>
              <a-form-item
                :label="$t('warehouse.shipment_name')"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 12 }"
              >
                <a-input v-model:value="shipmentAddModal.name"></a-input>
              </a-form-item>
            </a-col>
          </a-row>

          <!-- 表格 -->
          <a-row type="flex" justify="end">
            <a-col>
              <a-button class="mb-1" size="small" @click="handleAddRow">
                + {{ $t("common.add") }}</a-button
              >
            </a-col>
          </a-row>

          <a-table
            :data-source="shipmentAddModal.boxes"
            :columns="columnsShipmentAdd"
            :scroll="{ x: true, y: 500 }"
            :pagination="false"
            size="small"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template #scannable_box_no="{ record }">
              <a-input v-model:value="record.canScanedBoxNo" :maxlength="35" v-box-no-filter allow-clear></a-input>
            </template>

            <template #prediction_size="{ record }">
              <a-row :gutter="[12, 12]">
                <a-col>
                  <a-input-number
                    v-model:value="record.length"
                    :min="0"
                    allow-clear
                    :precision="0"
                  ></a-input-number>
                </a-col>
                <a-col>
                  <a-form-item class="mb-0">
                    <span>X</span>
                  </a-form-item>
                </a-col>
                <a-col>
                  <a-input-number
                    v-model:value="record.width"
                    :min="0"
                    allow-clear
                    :precision="0"
                  ></a-input-number>
                </a-col>
                 <a-col>
                  <a-form-item class="mb-0">
                    <span>X</span>
                  </a-form-item>
                </a-col>
                <a-col>
                  <a-input-number
                    v-model:value="record.height"
                    :min="0"
                    allow-clear
                    :precision="0"
                  ></a-input-number>
                  cm
                </a-col>
              </a-row>
            </template>

            <template #prediction_weight="{ record }">
              <a-input-number
                v-model:value="record.weight"
                :min="0"
                allow-clear
                :precision="3"
              ></a-input-number>
              kg
            </template>
            <template #operate="{ record }">
              <a-popconfirm
                v-if="
                  shipmentAddModal.boxes && shipmentAddModal.boxes?.length > 1
                "
                :title="$t('common.are_you_sure')"
                :ok-text="$t('common.confirm')"
                :cancel-text="$t('common.cancel')"
                @confirm="handleRemoveRow(record)"
              >
                <MinusCircleOutlined />
              </a-popconfirm>
            </template>
          </a-table>
        </a-modal>

        <!-- 费用支付 -->
        <Payment
          ref="refPayment"
          :orderNo="state.planNo"
          :currencyId="state.cost?.totalFee?.currencyId ?? ''"
          :unpaidSymbol="state.cost?.totalFee?.symbol ?? ''"
          :unpaidSum="state.cost?.totalFee?.value"
          @handlePayFee="handleOrderPay"
          :payModalTitleType="7"
          :loading="state.payModal.loading"
          v-model:visible="state.payModal.visible"
        ></Payment>
      </a-spin>
    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import { defineComponent, reactive, onMounted, onActivated, ref } from "vue";
import {
  Row,
  Col,
  Tag,
  Spin,
  Table,
  Input,
  Tooltip,
  Select,
  Button,
  Form,
  DatePicker,
  Space,
  Steps,
  message,
  Menu,
  Tabs,
  Dropdown,
  Popconfirm,
  Modal,
  InputNumber,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import {
  getAddShipmentDetail,
  getShipmentList,
  getShipmentListDropdownBox,
  getPackingList,
  updateBoxInfo,
  deleteBox,
  getPrintBoxSETB,
  getShipmentDetail,
  deleteShipment,
  getPrintShipmentSETableBatch,
  getPrintShipmentSETable,
  getPrintShipmentInSETBAll,
  addShipment,
  batchAddShipmentBox,
  getCost,
  cancelPlan,
  completeInBoundPlan,
} from "../../../api/modules/transit/inPlan/plan";
import {} from "../../../api/modules/common/index";
import {
  transportationMethod as transportationMethodEnum,
  deliveryWay as deliveryWayEnum,
  transitStatus as transitStatusEnum,
  boxStatus as boxStatusEnum,
  boxType as boxTypeEnum,
  transitInBoundErrorCustomerType as transitInBoundErrorCustomerTypeEnum,
  orderLogisticsFee as orderLogisticsFeeEnum,
} from "../../../enum/enum.json";
import {
  getLinkman,
  getAddressByLanguageV2,
  gToKg,
  kgToG,
  cmCubicToM,
} from "../../../utils/general";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import localPrint from "../../../utils/localPrint";
import { useTab } from "../../../hooks/tabs/index";
import { cloneDeep } from "lodash-es";
import Payment from "../../components/Payment.vue";
import CFee from "../../components/CFee.vue";
import filters from "@/filters";
export default defineComponent({
  name: "transit_in_plan_edit",
  components: {
    ADropdown: Dropdown,CFee,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    Payment,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputNumber: InputNumber,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ADatePicker: DatePicker,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ATag: Tag,
    ATooltip: Tooltip,
    ASpin: Spin,
    ASpace: Space,
    ASteps: Steps,
    AStep: Steps.Step,
    APopconfirm: Popconfirm,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
  },
  setup() {
    const router = useRouter();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const { delVisitedRoute } = useTab();
    const refFormBoxInfo = ref();
    const route = useRoute();

    const state = reactive({
      loading: false,
      tableLoading: false,
      planId: null,
      warehouseAddress: null,
      transportationMethod: null,
      deliveryType: null,
      trackingNo: null,
      containerType: null,
      containerNo: null,
      loading: false,
      warehouseAddress: null,
      wareZoneAppointmentDate: null,
      wareTimeZone: null, //仓库时区
      transitStatus: null,
      tab: "packingList",
      packingList: [],
      packingListPageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      shipmentList: [],
      shipmentListPageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      shipmentListDropdownBox: [],
      shipmentListDropdownBoxLoading: false,

      cost: null,
      payModal: {
        visible: false,
        loading: false,
      },
    });

    const _getPageData = () => {
      return {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      };
    };

    const handleCancel = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "transit_in_plan_list" });
    };

    const handleNextStep = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "transit_in_plan_edit", params: { id: 1 } });
    };

    const getAddress = () => {
      if (!state.warehouseAddress) {
        return;
      }
      return getAddressByLanguageV2(state.warehouseAddress, getters.language);
    };

    const handleChangeTab = () => {
      if (state.tab == "packingList") {
        if (!state.packingList || state.packingList?.length == 0) {
          funcGetPackingList();
        }
      } else {
        if (!state.shipmentList || state.shipmentList?.length == 0) {
          funcGetShipmentList();
        }
      }
    };

    //----------------------装箱清单 start---------------------------------------
    const columnsPackingList = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return ((state.packingListPageData.currentIndex - 1) * state.packingListPageData.maxResultCount) + (index + 1);
        },
        fixed: "left",
        width: 30,
      },
      {
        dataIndex: "canScanedBoxNo",
        slots: {
          title: "canScanedBoxNoTitle",
        },
        width: 100,
      },
      {
        dataIndex: "name",
        title: () => vueI18n.t("warehouse.shipment_name"),
        width: 100,
      },
      {
        dataIndex: "boxNo",
        title: () => vueI18n.t("warehouse.box_id"),
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.prediction_size"),
        slots: {
          customRender: "prediction_size",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.prediction_weight"),
        slots: {
          customRender: "prediction_weight",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.box_type"),
        slots: {
          customRender: "box_type",
        },
        width: 100,
      },
      {
        dataIndex: "operate",
        title: () => vueI18n.t("common.operation"),
        fixed: "right",
        slots: {
          customRender: "operateCustom",
        },
        width: 130,
      },
    ];

    const rulesBoxInfo = {
      shipmentId: [
        {
          required: true,
          whitespace: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.shipment_name"),
            ]);
          },
        },
      ],
      canScanedBoxNo: [
        {
          required: true,
          whitespace: true,
          validator: (rule, value) => {
            if (boxInfoModal.box.canScanedBoxNo) {
              return Promise.resolve();
            }
            return Promise.reject(
              vueI18n.t("common.p0_is_required", [
                vueI18n.t("warehouse.scannable_box_no"),
              ])
            );
          },
        },
      ],
      predictionSize: [
        {
          validator: (rule, value) => {
            if (
              !boxInfoModal.box.length ||
              !boxInfoModal.box.width ||
              !boxInfoModal.box.height
            ) {
              return Promise.reject(
                vueI18n.t("common.p0_is_not_complete", [
                  vueI18n.t("warehouse.prediction_size"),
                ])
              );
            }
            return Promise.resolve();
          },
        },
      ],
      weight: [
        {
          required: true,
          whitespace: true,
          validator: (rule, value) => {
            if (boxInfoModal.box.weight > 0) {
              return Promise.resolve();
            }
            return Promise.reject(
              vueI18n.t("common.p0_is_required", [
                vueI18n.t("warehouse.prediction_weight"),
              ])
            );
          },
        },
      ],
    };

    const boxInfoModal = reactive({
      loading: false,
      visible: false,
      shipmentId: null,
      shipmentDetailId: null,
      boxNo: null,
      box: {
        length: null,
        width: null,
        height: null,
        weight: null,
        canScanedBoxNo: null,
      },
    });

    const handleShowEditBoxInfo = (record) => {
      boxInfoModal.visible = true;
      refFormBoxInfo.value?.clearValidate();
      refFormBoxInfo.value?.resetFields();
      funcGetShipmentListDropdownBox();
      boxInfoModal.planId = state.planId;
      boxInfoModal.box = {
        length: record.containerBoxSize.length,
        width: record.containerBoxSize.width,
        height: record.containerBoxSize.height,
        weight: record.containerBoxSize.weight,
        canScanedBoxNo: record.canScanedBoxNo,
      };
      boxInfoModal.shipmentId = record.shipmentId;
      boxInfoModal.shipmentDetailId = record.shipmentDetailId;
      boxInfoModal.boxNo = record.boxNo;

      boxInfoModal.box.weight = gToKg(boxInfoModal.box.weight);
    };

    const handleSaveBoxInfoModal = () => {
      refFormBoxInfo.value
        .validate()
        .then(() => {
          boxInfoModal.loading = true;
          boxInfoModal.box.weight = kgToG(boxInfoModal.box.weight);
          return updateBoxInfo({ ...boxInfoModal });
        })
        .then(({ result }) => {
          if (result) {
            boxInfoModal.loading = false;
            boxInfoModal.visible = false;
            funcGetPackingList();
            funcGetShipmentList();
            handelGetCost();
            message.success(vueI18n.t("common.succeed"));
          } else {
            boxInfoModal.box.weight = gToKg(boxInfoModal.box.weight);
          }
        })
        .catch((error) => {
          boxInfoModal.box.weight = gToKg(boxInfoModal.box.weight);
          boxInfoModal.loading = false;
        });
    };

    //打印-----------------------------
    const handlePrintBoxSETB = (record) => {
      let pdfUrl = getPrintBoxSETB({ ...record, planId: state.planId });
      localPrint(pdfUrl);
    };

    const handlePagePackingList = async (pageData) => {
      state.packingListPageData.skipCount = pageData.skipCount;
      state.packingListPageData.maxResultCount = pageData.maxResultCount;
      funcGetPackingList();
    };

    //-----------------------装箱清单 end--------------------------------------

    //-----------------确认删除箱 start--------------------

    const deleteBoxModal = reactive({
      loading: false,
      visible: false,
      planId: null,
      shipmentId: null,
      shipmentDetailId: null,
      name: null,
      canScanedBoxNo: null,
      boxNo: null,
    });

    const handleShowBoxDeleteModal = (record) => {
      deleteBoxModal.planId = state.planId;
      deleteBoxModal.name = null;
      deleteBoxModal.canScanedBoxNo = null;
      deleteBoxModal.boxNo = null; //避免缓存
      Object.assign(deleteBoxModal, record);
      deleteBoxModal.visible = true;
    };

    const handleBoxDelete = () => {
      deleteBoxModal.loading = true;
      deleteBox({ ...deleteBoxModal })
        .then(({ result }) => {
          deleteBoxModal.visible = false;
          deleteBoxModal.loading = false;
          funcGetPackingList();
          funcGetShipmentList();
          handelGetCost();
        })
        .catch(() => {
          deleteBoxModal.loading = false;
        });
    };
    //-----------------确认删除箱 end--------------------

    //-----------------------货件列表 start--------------------------------------
    const columnsShipmentList = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 30,
      },

      {
        dataIndex: "name",
        title: () => vueI18n.t("warehouse.shipment_name"),
        width: 100,
      },
      {
        dataIndex: "seShipmentNo",
        title: () => vueI18n.t("warehouse.se_shipment_number"),
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "box_amount",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.total_volume_of_shipment"),
        slots: {
          customRender: "volume",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.total_weight_of_the_shipment"),
        slots: {
          customRender: "weight",
        },
        width: 100,
      },

      {
        dataIndex: "operate",
        fixed: "right",
        slots: {
          title: "operate",
          customRender: "operateCustom",
        },
        width: 130,
      },
    ];

    const handlePrintShipmentSETableBatch = (record) => {
      let pdfUrl = getPrintShipmentSETableBatch(state.planId);
      localPrint(pdfUrl);
    };

    const handlePrintShipmentSETable = (record) => {
      let pdfUrl = getPrintShipmentSETable({ ...record, planId: state.planId });
      localPrint(pdfUrl);
    };

    const handlePrintShipmentInSETBAll = (record) => {
      let pdfUrl = getPrintShipmentInSETBAll({
        ...record,
        planId: state.planId,
      });
      localPrint(pdfUrl);
    };

    const handlePageShipmentList = async (pageData) => {
      state.shipmentListPageData.skipCount = pageData.skipCount;
      state.shipmentListPageData.maxResultCount = pageData.maxResultCount;
      funcGetShipmentList();
    };
    //-----------------------货件列表 end--------------------------------------

    //-----------------------货件详情与删除 modal start--------------------------------------
    const columnsShipmentDetail = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        dataIndex: "canScanedBoxNo",
        title: () => vueI18n.t("warehouse.scannable_box_no"),
        width: 100,
      },
      {
        dataIndex: "boxNo",
        title: () => vueI18n.t("warehouse.box_id"),
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.prediction_size"),
        slots: {
          customRender: "predictionSize",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.prediction_weight"),
        slots: {
          customRender: "predictionWeight",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.box_type"),
        slots: {
          customRender: "boxType",
        },
        width: 100,
      },
    ];

    const shipmentDetailModal = reactive({
      loading: false,
      visible: null,
      name: null,
      seShipmentNo: null,
      list: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      isDelete: false,
      deleteLoading: false,
    });

    const handleShowShipmentDetailModal = (record, isDelete) => {
      shipmentDetailModal.visible = true;
      shipmentDetailModal.planId = state.planId;
      shipmentDetailModal.shipmentId = record.shipmentId;
      shipmentDetailModal.name = record.name;
      shipmentDetailModal.seShipmentNo = record.seShipmentNo;
      shipmentDetailModal.isDelete = isDelete ?? false;
      shipmentDetailModal.pageData = _getPageData();
      //这里翻页有bug,第一个翻页选择100条/页.还另1货件, 会显示100条/页,但是实际是_getPageData()---todu.  DUG CY
      funcGetShipmentDetail();
    };

    const handleShipmentDelete = (record) => {
      shipmentDetailModal.deleteLoading = true;
      deleteShipment({ ...shipmentDetailModal })
        .then(({ result }) => {
          shipmentDetailModal.visible = false;
          shipmentDetailModal.deleteLoading = false;
          message.success(vueI18n.t("common.succeed"));
          funcGetShipmentList();
          funcGetPackingList();
          handelGetCost();
        })
        .catch(() => {
          shipmentDetailModal.deleteLoading = false;
        });
    };

    const funcGetShipmentDetail = (record) => {
      shipmentDetailModal.loading = true;
      getShipmentDetail({
        ...shipmentDetailModal,
        ...shipmentDetailModal.pageData,
      })
        .then(({ result }) => {
          if (result) {
            let { items = [], totalCount = 0 } = result;
            shipmentDetailModal.list = items;
            shipmentDetailModal.pageData.totalCount = totalCount * 1;
          }
          shipmentDetailModal.loading = false;
        })
        .catch(() => {
          shipmentDetailModal.loading = false;
        });
    };

    const handlePageShipmentDetailModal = async (pageData) => {
      shipmentDetailModal.pageData.skipCount = pageData.skipCount;
      shipmentDetailModal.pageData.maxResultCount = pageData.maxResultCount;
      funcGetShipmentDetail();
    };
    //-----------------------货件详情与删除 modal start--------------------------------------

    //-----------------------添加货件 start--------------------------------------
    const shipmentAddModal = reactive({
      loading: false,
      visible: null,
      planId: null,
      name: null,
      shipmentId: null,
      addType: "create",
      boxes: [],
    });

    const handleShowShipmentAddModal = (record) => {
      shipmentAddModal.visible = true;
      shipmentAddModal.planId = state.planId;
      shipmentAddModal.name = null;
      shipmentAddModal.shipmentId = null;
      shipmentAddModal.boxes = [
        {
          canScanedBoxNo: null,
          length: null,
          width: null,
          height: null,
          weight: null,
          key: Date.now(),
        },
      ];
      funcGetShipmentListDropdownBox();
    };

    const _checkData = () => {
      if (
        (shipmentAddModal.addType == "create" && !shipmentAddModal.name) ||
        (shipmentAddModal.addType == "append" && !shipmentAddModal.shipmentId)
      ) {
        message.error(
          vueI18n.t("common.p0_is_required", [
            vueI18n.t("warehouse.shipment_name"),
          ])
        );
        return true;
      }

      return shipmentAddModal.boxes.some((x, index) => {
        let canScanedBoxNoIndex = shipmentAddModal.boxes.findIndex(
          (i, iIndex) => iIndex != index && i.canScanedBoxNo == x.canScanedBoxNo
        );

        if (canScanedBoxNoIndex >= 0) {
          message.error(
            vueI18n.t(
              "common.serial_number_p0_is_the_same_as_the_scannable_container_number_1",
              [index + 1, canScanedBoxNoIndex + 1]
            )
          );
          return true;
        }

        if (
          !x.canScanedBoxNo ||
          !x.length ||
          !x.width ||
          !x.height ||
          !x.weight
        ) {
          message.error(
            vueI18n.t("common.data_serial_number_p0_is_incomplete", [index + 1])
          );
          return true;
        }
      });
    };

    const handleSaveShipmentAddModal = () => {
      //循环验证,true为未通过验证
      if (_checkData()) {
        return;
      }

      let tempBoxes = cloneDeep(shipmentAddModal.boxes);
      tempBoxes.forEach((x) => {
        x.weight = kgToG(x.weight);
      });

      let parameter = {
        boxes: tempBoxes,
        name: shipmentAddModal.name,
        planId: shipmentAddModal.planId,
        shipmentId: shipmentAddModal.shipmentId,
      };
      shipmentAddModal.loading = true;

      if (shipmentAddModal.addType == "create") {
        addShipment({ ...parameter })
          .then(() => {
            shipmentAddModal.loading = false;
            shipmentAddModal.visible = false;
            funcGetShipmentList();
            funcGetPackingList();
            handelGetCost();
            message.success(vueI18n.t("common.succeed"));
          })
          .catch(() => {
            shipmentAddModal.loading = false;
          });
      } else {
        batchAddShipmentBox({ ...parameter })
          .then(() => {
            shipmentAddModal.loading = false;
            shipmentAddModal.visible = false;
            funcGetShipmentList();
            funcGetPackingList();
            handelGetCost();
            message.success(vueI18n.t("common.succeed"));
          })
          .catch(() => {
            shipmentAddModal.loading = false;
          });
      }
    };

    const columnsShipmentAdd = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 50,
      },
      {
        title: () => vueI18n.t("warehouse.scannable_box_no"),
        slots: {
          customRender: "scannable_box_no",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.prediction_size"),
        width: 250,
        slots: {
          customRender: "prediction_size",
        },
      },
      {
        title: () => vueI18n.t("warehouse.prediction_weight"),
        width: 90,
        slots: {
          customRender: "prediction_weight",
        },
      },
      {
        title: "",
        width: 50,
        slots: {
          customRender: "operate",
        },
      },
    ];

    const handleAddRow = () => {
      shipmentAddModal.boxes.push({
        canScanedBoxNo: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        key: Date.now(),
      });
    };

    const handleRemoveRow = (record) => {
      shipmentAddModal.boxes = shipmentAddModal.boxes.filter(
        (x) => x?.key != record?.key
      );
    };

    //-----------------------添加货件 end--------------------------------------
    const funcGetDetailById = () => {
      state.loading = true;
      getAddShipmentDetail(state?.planId)
        .then(({ result }) => {
          Object.assign(state, result);
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    };

    const funcGetPackingList = () => {
      state.tableLoading = true;
      getPackingList({ planId: state.planId, ...state.packingListPageData })
        .then((res) => {
          if (res.result) {
            let { items = [], totalCount = 0 } = res.result;
            state.packingList = items;
            state.packingListPageData.totalCount = totalCount * 1;
          }
          state.tableLoading = false;
        })
        .catch(() => {
          state.tableLoading = false;
        });
    };

    const funcGetShipmentList = () => {
      state.tableLoading = true;
      getShipmentList({ planId: state.planId, ...state.shipmentListPageData })
        .then((res) => {
          if (res.result) {
            let { items = [], totalCount = 0 } = res.result;
            state.shipmentList = items;
            state.shipmentListPageData.totalCount = totalCount * 1;
          }
          state.tableLoading = false;
        })
        .catch(() => {
          state.tableLoading = false;
        });
    };

    const funcGetShipmentListDropdownBox = () => {
      state.shipmentListDropdownBoxLoading = true;
      getShipmentListDropdownBox(state.planId)
        .then((res) => {
          if (res.result) {
            state.shipmentListDropdownBox = res.result;
          }
          state.shipmentListDropdownBoxLoading = false;
        })
        .catch(() => {
          state.shipmentListDropdownBoxLoading = false;
        });
    };

    const handelGetCost = () => {
      state.loading = true;
      getCost(state.planId)
        .then(({ result }) => {
          if (result) {
            state.cost = result;
          }
          state.loading = false;
        })
        .catch(() => {
          state.loading = false;
        });
    };

    const handleCancelPlan = () => {
      state.loading = true;
      cancelPlan(state.planId)
        .then(({ result }) => {
          state.loading = false;
          delVisitedRoute(router.currentRoute.value);
          router.push({
            name: "transit_in_plan_list",
          });
        })
        .catch(() => {
          state.loading = false;
        });
    };
    const handleOrderPay = async () => {
      state.payModal.loading = true;
      completeInBoundPlan(state.planId,filters.amountToFixed2(state.cost?.totalFee?.value))
        .then((res) => {
          state.payModal.loading = false;
          message.success(vueI18n.t("common.succeed"));
          delVisitedRoute(router.currentRoute.value);
          router.push({
            name: "transit_in_plan_list",
          });
          state.payModal.visible = false;
        })
        .catch(() => {
          state.payModal.loading = false;
        });
    };

    const refPayment = ref(null);
    const handleShowPayModal = (record) => {
      state.payModal.visible = true;
      refPayment.value.handleShowPayOrder();
    };

    onMounted(() => {});

    onActivated(() => {
      state.planId = route.params.id;
      if (state?.planId) {
        funcGetDetailById();
        handleChangeTab();
        handelGetCost();
      }
    });

    return {
      state,
      transportationMethodEnum,
      deliveryWayEnum,
      transitStatusEnum,
      boxStatusEnum,
      boxTypeEnum,
      transitInBoundErrorCustomerTypeEnum,
      gToKg,
      kgToG,
      cmCubicToM,
      handleNextStep,
      handleCancel,
      getAddress,
      getLinkman,
      handleChangeTab,

      columnsPackingList,
      refFormBoxInfo,
      rulesBoxInfo,
      boxInfoModal,
      handleShowEditBoxInfo,
      handleSaveBoxInfoModal,
      handlePrintBoxSETB,
      handlePagePackingList,

      deleteBoxModal,
      handleBoxDelete,
      handleShowBoxDeleteModal,

      columnsShipmentList,
      handlePrintShipmentSETableBatch,
      handlePrintShipmentSETable,
      handlePrintShipmentInSETBAll,
      handlePageShipmentList,

      columnsShipmentDetail,
      shipmentDetailModal,
      handleShowShipmentDetailModal,
      handlePageShipmentDetailModal,
      handleShipmentDelete,

      shipmentAddModal,
      columnsShipmentAdd,
      handleShowShipmentAddModal,
      handleSaveShipmentAddModal,
      handleAddRow,
      handleRemoveRow,

      refPayment,
      handleCancelPlan,
      handleOrderPay,
      handleShowPayModal,

      handelGetCost,
    };
  },
});
</script>

<style lang="less" scoped></style>
